import React from 'react';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Web3 from 'web3';
import { LazyLoadImage } from 'react-lazy-load-image-component';


export default function ConfirmationCard({ mintPrice, goBack, onClick, document, signature }) {
    return (
        <div className="mb-5">
            <div className="hero-custom" style={{backgroundColor: '#FFEBE0', marginTop: '80px', paddingBottom: '80px'}}>
                <Container>
                    <p className="m-0 top-headline" style={{paddingTop: '100px', paddingBottom: '20px'}}>Mr.Kaves On Meta Collection</p>
                    <h1 className="headline" style={{paddingBottom: '20px'}}>Confirm Transaction</h1>

                    <p className="text-center px-4 my-0 mx-auto fw-bold" style={{maxWidth: '600px'}}>Please do not close this window. Your progress will not be saved.</p>

                    <p className="text-center px-4 mt-4 mb-0 mx-auto fw-bold" style={{maxWidth: '600px'}}>Continue below to submit your transaction.</p>
                </Container>
            </div>

            <Container className="py-4">
                <Row style={{maxWidth: '100%'}}>
                    <Col md={12} lg={6} className="d-flex align-items-center justify-content-center p-5 order-1 order-md-0">
                        <div className="text-center" style={{maxWidth: '100%'}}>
                            <h2 className="text-start second-headline">Piece Name</h2>
                            <p className="text-start mb-4" style={{fontSize: '1rem', overflowWrap: 'break-word'}}>{document.name}</p>

                            <h2 className="text-start second-headline">Signature</h2>
                            <p className="text-start mb-4" style={{fontSize: '1rem', maxWidth: '600px', overflowWrap: 'break-word'}}>{signature}</p>

                            <h2 className="text-start second-headline">Price</h2>
                            <p className="text-start mb-0" style={{fontSize: '1rem', maxWidth: '600px', overflowWrap: 'break-word'}}>{Web3.utils.fromWei(mintPrice, 'ether')} ETH + GAS</p>

                            <div className="mt-4 text-start">
                                <Button className="my-2 me-2 btn-custom rounded-0" onClick={() => goBack()}>
                                    Go Back
                                </Button>
                                <Button className="my-2 me-2 btn-custom rounded-0" onClick={() => onClick()}>
                                    Confirm and Continue
                                </Button>
                            </div>
                        </div>
                    </Col>
                    <Col md={12} lg={6} className="d-flex align-items-center justify-content-center pb-0 pb-md-5 pt-5 px-5 order-0 order-md-1">
                        <LazyLoadImage effect="opacity" alt={document.name} src={document.image} style={{maxWidth: '100%'}} />
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
