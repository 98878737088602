import React, { useState, useEffect, useContext } from 'react';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import LinkNav from './components/LinkNav';
import ErrorGate from './components/ErrorGate';
import TokenTemplateCard from './components/TokenTemplateCard';
import LoadingCard from './components/LoadingCard';
import {
    ETH_SEND_TRANSACTION
} from './helpers/constants';
import {
    mint,
    saleIsActive,
    signFileSignatureToken,
} from './helpers/web3';
import { WalletContext } from './providers/WalletProvider';
import SigningCard from './components/SigningCard';
import ConfirmationCard from './components/ConfirmationCard';
import ProcessingCard from './components/ProcessingCard';
// import PageNav from './components/PageNav';


export default function Mint() {
    const [isActive, setIsActive] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isSigning, setIsSigning] = useState(false);
    const [signature, setSignature] = useState('');
    const [documentId, setDocumentId] = useState('');
    const [document, setDocument] = useState({});
    const [tokenName, setTokenName] = useState('');
    const [mintPrice, setMintPrice] = useState('');
    
    const { config, setConfig, error, setError, onConnect, resetApp } = useContext(WalletContext);

    useEffect(() => {
        if (config.web3 && config.address !== '') {
            const _fetch = async () => {
                setLoading(true);
                setConfig((oldConfig) => ({...oldConfig, result: null}));

                try {
                    const saleIsActiveResult = await saleIsActive(config.chainId, config.web3);
                    setIsActive(saleIsActiveResult);

                    setError(null);
                }
                catch (err) {
                    console.log('this do be error');
                    console.error(err);
                    setError(err);
                }

                setLoading(false);
            };
            _fetch();
        }
    }, [config.address, config.web3, config.chainId, setConfig, setError]);

    const startMint = (_documentId, _document, _tokenName, _mintPrice) => {
        setIsSigning(true);
        setDocumentId(_documentId);
        setDocument(_document);
        setTokenName(_tokenName);
        setMintPrice(_mintPrice);
        window.scrollTo(0, 0);
    };

    const signingGoBack = () => {
        setIsSigning(false);
        setSignature('');
        window.scrollTo(0, 0);
    };

    const confirmationGoBack = () => {
        setSignature('');
        window.scrollTo(0, 0);
    };

    const startFileSignature = async () => {
        const { web3, chainId, ethers } = config;

        if (!web3) {
            return;
        }

        if (!startFileSignature) {
            throw new Error('Missing matching contract calls');
        }

        try {
            setConfig((oldConfig) => ({ ...oldConfig, web3, pendingRequest: true }));

            const sig = await signFileSignatureToken(tokenName, document, documentId, chainId, web3, ethers);
            setSignature(sig);

            setConfig((oldConfig) => ({ ...oldConfig, web3, pendingRequest: false }));
        }
        catch (err) {
            console.error(err);
            setConfig((oldConfig) => ({ ...oldConfig, web3, pendingRequest: false, result: null }));
            setError(err);
        }
    };

    const startSendTransaction = async () => {
        const { web3, address, chainId } = config;

        if (!web3 || signature === '') {
            return;
        }

        if (!mint) {
            throw new Error('Missing matching contract calls');
        }

        try {
            setConfig((oldConfig) => ({...oldConfig, pendingRequest: true }));
            setIsSigning(false);

            const { signature: _signature, result } = await mint(signature, tokenName, document, documentId, mintPrice, address, chainId, web3);
            console.log('RESULTS', _signature, result);

            const formattedResult = {
                action: ETH_SEND_TRANSACTION,
                result
            };

            setConfig((oldConfig) => ({
                ...oldConfig,
                web3,
                pendingRequest: false,
                result: formattedResult || null
            }));
        }
        catch (err) {
            console.error(err);
            setConfig((oldConfig) => ({ ...oldConfig, web3, pendingRequest: false, result: null }));
            setError(err);
        }
    };

    return (
        <div>
            <LinkNav address={config.address} resetApp={resetApp} chainId={config.chainId} />
            {
                config.pendingRequest || loading ? 
                    <ProcessingCard />
                :
                <>
                    {
                        error ? <ErrorGate /> :
                            config.address === '' ? 
                                <Container style={{marginTop: '80px'}}>
                                    <p className="m-0 top-headline" style={{paddingTop: '100px', paddingBottom: '20px'}}>Mr.Kaves On Meta Collection</p>
                                    <h1 className="headline" style={{paddingBottom: '20px'}}>Connect Wallet</h1>
                                    <div className="text-center">
                                        <Button className="btn-custom rounded-0" onClick={onConnect}>
                                            Continue
                                        </Button>
                                    </div>
                                </Container>
                            :
                                isActive ? 
                                    isSigning && signature === '' ? 
                                        <SigningCard goBack={signingGoBack} onClick={startFileSignature} document={document} documentId={documentId} mintPrice={mintPrice} />
                                    :
                                        isSigning && signature !== '' ? 
                                            <ConfirmationCard goBack={confirmationGoBack} onClick={startSendTransaction} mintPrice={mintPrice} document={document} signature={signature} />
                                        :
                                            config.result ? 
                                                <LoadingCard chainId={config.chainId} signature={signature} transactionHash={config.result.result} web3={config.web3} />
                                            :
                                                <>
                                                    <div className="hero-custom" style={{backgroundColor: '#FFEBE0', marginTop: '80px', paddingBottom: '80px'}}>
                                                        <Container>
                                                            <p className="m-0 top-headline" style={{paddingTop: '100px', paddingBottom: '20px'}}>Mr.Kaves On Meta Collection</p>
                                                            <h1 className="headline" style={{paddingBottom: '20px'}}>UrbanScapes NFTs</h1>
                                                            <p className="my-0 mx-auto px-4 mb-4" style={{maxWidth: '600px'}}>Welcome to the debut of Mr. Kaves “UrbanScapes" digital art collection into the NFT world. Created in his signature style, the NFT takes his canvas painting displayed at the Alpha House crypto think-tank and launches it on the blockchain. Mr. Kaves has created a series of iterations of his masterpiece for collection by true art fans around the globe.</p>
                                                            <div>
                                                                <a href="https://metacollect.io/artists/mr-kaves" target="_blank" rel="noreferrer" className="btn btn-custom rounded-0">Meet Mr. Kaves</a>
                                                            </div>
                                                        </Container>
                                                    </div>
                                                    <Container>
                                                        {
                                                            JSON.parse(process.env.REACT_APP_TEMPLATE_IDS).map((id, i) => (
                                                                <TokenTemplateCard showBorder={false} id={id} startSendTransaction={startMint} key={`token-template-${i}`} chainId={config.chainId} web3={config.web3} />
                                                            ))
                                                        }
                                                    </Container>
                                                    <div className="hero-custom" style={{backgroundColor: 'rgba(255, 235, 224, 0.2)', paddingTop: '80px', paddingBottom: '80px'}}>
                                                        <Container className="px-5">
                                                            <div className="text-center">
                                                                <h2 className="text-start second-headline mb-4">Exclusive Utilities</h2>
                                                                
                                                                <p className="text-start px-4 mt-3 mb-4 mx-auto fw-bold">In addition to the digital format, this project carries the following utilities for collectors:</p>
                                                                
                                                                <Row>
                                                                    <Col sm={12} md={6}>
                                                                        <ul className="text-start">
                                                                            <li className="mb-3">Four people receive an original Mr. Kaves art piece, valued at up to $500,000</li>
                                                                            <li className="mb-3">One person receives a unique Mr. Kaves art piece the night of the event</li>
                                                                            <li className="mb-3">One person receives an eight-person dinner party at Mr. Kaves' restaurant - Brooklyn Firefly</li>
                                                                            <li className="mb-3">Access to NFT lookbook on Polygon</li>
                                                                        </ul>
                                                                    </Col>
                                                                    <Col sm={12} md={6}>
                                                                        <div className="text-start">
                                                                            <ul>
                                                                                <li className="mb-3">Access to AlphaDAO's general membership NFTs</li>
                                                                                <li className="mb-3">Entry into a tattoo giveaway from Mr. Kaves</li>
                                                                                <li className="mb-3">Mr. Kaves studio visit</li>
                                                                            </ul>
                                                                        </div>
                                                                    </Col>
                                                                </Row>

                                                                <p className="text-start px-4 my-4 mx-auto fw-bold">For a full list of utilities, please visit: <a href="https://metacollect.io/urbanscapes" target="_blank" rel="noreferrer">www.metacollect.io/urbanscapes</a></p>
                                                            </div>
                                                        </Container>
                                                    </div>
                                                </>                                                  
                                :
                                    <Container style={{marginTop: '80px'}}>
                                        <p className="m-0 top-headline" style={{paddingTop: '100px', paddingBottom: '20px'}}>Mr.Kaves On Meta Collection</p>
                                        <h1 className="headline" style={{paddingBottom: '20px'}}>Urbanscapes NFTs</h1>

                                        <p className="text-center px-4 mt-4 mb-0 mx-auto fw-bold" style={{maxWidth: '600px'}}>The token sale is currently closed.</p>
                                    </Container>
                    }
                </>
            }
        </div>
    );
}
