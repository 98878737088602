import React, { useState, useContext, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';

import { WalletContext } from './providers/WalletProvider';
import { tokensOfOwner } from './helpers/web3';
import LinkNav from './components/LinkNav';
import TokenCard from './components/TokenCard';


export default function MyTokens() {
    const { config, resetApp, onConnect } = useContext(WalletContext);
    const [tokenIds, setTokenIds] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const _fetch = async () => {
            try {
                if (config.address !== '') {
                    setIsLoading(true);

                    const _tokenIds = await tokensOfOwner(config.address, config.chainId, config.web3);
                    setTokenIds(_tokenIds);
                    console.log('TOKEN IDS', _tokenIds);
    
                    setIsLoading(false);
                }
            }
            catch(e) {
                console.log('Error!', e);
            }
        };
        _fetch();
    }, [config.address, config.chainId, config.web3]);

    return (
        <div>
            <LinkNav address={config.address} resetApp={resetApp} chainId={config.chainId} />
            {
                config.address === '' ? 
                    <Container className="mb-5" style={{marginTop: '80px'}}>
                        <p className="m-0 top-headline" style={{paddingTop: '100px', paddingBottom: '20px'}}>Mr.Kaves On Meta Collection</p>
                        <h1 className="headline" style={{paddingBottom: '20px'}}>Connect Wallet</h1>
                        <div className="text-center">
                            <Button className="btn-custom rounded-0" onClick={onConnect}>
                                Continue
                            </Button>
                        </div>
                    </Container>
                :
                    <>
                        <div className="hero-custom" style={{backgroundColor: '#FFEBE0', marginTop: '80px', paddingBottom: '80px'}}>
                            <Container>
                                <p className="m-0 top-headline" style={{paddingTop: '100px', paddingBottom: '20px'}}>Mr.Kaves On Meta Collection</p>
                                <h1 className="headline" style={{paddingBottom: '20px'}}>My Tokens</h1>
                                <p className="my-0 mx-auto px-4 fw-bold" style={{maxWidth: '600px'}}>You own {tokenIds.length} {process.env.REACT_APP_TOKEN_NAME} ({process.env.REACT_APP_TOKEN_SYMBOL}) tokens.</p>
                            </Container>
                        </div>

                        {
                            isLoading ? 
                                <div className="mt-5">
                                    <Spinner animation="border" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner>
                                    <h5 className='text-white'>Your transaction is processing...</h5>
                                </div>
                            :
                                <Container className="mb-5">
                                    {
                                        tokenIds.map((t, i) => (
                                            <TokenCard key={`token-card-${i}`} chainId={config.chainId} web3={config.web3} id={t} address={config.address} />
                                        ))
                                    }
                                </Container>
                        }
                    </>
            }
        </div>
    );
}
