import React,{ useEffect, useState } from 'react';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';
import { LazyLoadImage } from 'react-lazy-load-image-component';

// import { db } from '../helpers/base';
import { ownerOf, tokenURI } from '../helpers/web3';

import 'react-lazy-load-image-component/src/effects/opacity.css';


export default function TokenCard({ id, chainId, web3, address }) {
    const [metadata, setMetadata] = useState({});
    const [_ownerOf, setOwnerOf] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const _fetch = async () => {
            try {
                if (id !== '') {
                    setIsLoading(true);
                    const _owner = await ownerOf(id, chainId, web3);
                    const _tokenURI = await tokenURI(id, chainId, web3);
                    const res = await axios.get(_tokenURI);
                    console.log('METADATA', res.data);
                    setMetadata(res.data);
                    setOwnerOf(_owner);
                    setIsLoading(false);
                }
            }
            catch(e) {
                console.log('Error!', e);
            }
        };
        _fetch();
    }, [id, chainId, web3]);

    return (
        <>
            {
                isLoading ? 
                    <div className="my-4">
                        <Spinner animation="border" role="status" size="sm">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div>
                :
                    <div className="border-bottom border-dark py-4 mb-5">
                        <Row>
                            <Col md={12} lg={6} className="d-flex align-items-center justify-content-center p-5 order-1 order-md-0">
                                <div className="text-center" style={{maxWidth: '100%'}}>
                                    <h6 className="text-start mb-3 subline" style={{fontWeight: 'bold'}}>Token ID: {id}</h6>
                                    <h2 className="text-start second-headline">{metadata.name}</h2>
                                    <p className="text-start" style={{fontSize: '1rem', maxWidth: '600px'}}>{metadata.description}</p>

                                    <h2 className="text-start second-headline">Owner</h2>
                                    <p className="text-start mb-4" style={{fontSize: '1rem', maxWidth: '600px', overflowWrap: 'break-word'}}>{_ownerOf} {_ownerOf === address ? '(you)' : ''}</p>
                                    

                                    <h2 className="text-start second-headline">Attributes</h2>
                                    <Row>
                                        {
                                            metadata.attributes && metadata.attributes.length > 0 ? 
                                                metadata.attributes.map((m, i) => (
                                                    <Col key={`metadata-trait-${i}`} sm={12} md={6}>
                                                        <Card className='mb-3 rounded-0'>
                                                            <Card.Body className="bg-light">
                                                                <h6 className="subline text-center">{m.trait_type}</h6>
                                                                <p className="mb-0" style={{fontSize: '1rem'}}>{m.value}</p>
                                                            </Card.Body>
                                                        </Card>
                                                    </Col>
                                                ))
                                            :
                                                <p>No attributes.</p>
                                        }
                                    </Row>

                                    <div className="mt-4 text-start">
                                        <a href={`https://testnets.opensea.io/assets/rinkeby/${process.env.REACT_APP_CONTRACT_ADDRESS}/${id}`} target="_blank" rel="noreferrer" style={{fontSize: '1rem'}} className="btn btn-primary btn-custom my-2 me-2 rounded-0">View on OpenSea</a>
                                        <a href={`https://rinkeby.etherscan.io/token/${process.env.REACT_APP_CONTRACT_ADDRESS}?a=${id}`} target="_blank" rel="noreferrer" style={{fontSize: '1rem'}} className="btn btn-primary btn-custom my-2 me-2 rounded-0">View on block explorer</a>
                                    </div>
                                </div>
                            </Col>
                            <Col md={12} lg={6} className="d-flex align-items-center justify-content-center pb-0 pb-md-5 pt-5 px-5 order-0 order-md-1">
                                <LazyLoadImage effect="opacity" alt={metadata.name} src={metadata.image} style={{maxWidth: '100%'}} />
                            </Col>
                        </Row>
                    </div>    
            }
        </>
    );
}
